/* eslint-disable no-undef */
const axios = require('axios')

export default class {
  static loadPlacesApi(googlePlaces) {
    return new Promise(resolve => {
      if (document.getElementById('placesApi')) resolve()
      else {
        const addressScript = document.createElement('script')
        addressScript.src = `https://maps.googleapis.com/maps/api/js?key=${googlePlaces}&libraries=places`
        addressScript.id = 'placesApi'
        addressScript.async = true
        addressScript.onload = () => resolve()
        document.body.appendChild(addressScript)
      }
    })
  }

  static initAutocomplete(inputId) {
    const placesInput = document.getElementById(inputId)
    const options = {
      componentRestrictions: { country: ['us', 'ca'] },
      fields: ['address_components'],
      types: ['address'],
      strictBounds: false
    }
    // eslint-disable-next-line no-undef
    return new google.maps.places.Autocomplete(placesInput, options)
  }

  static validateAddress(googleAddressValidation, inputAddress) {
    const url = `https://addressvalidation.googleapis.com/v1:validateAddress?key=${googleAddressValidation}`
    const payload = {
      address: inputAddress,
      enableUspsCass: true
    }
    return axios.post(url, payload).then(({ data }) => {
      // use uspsZip if available, default to googleZip
      const uspsZip = data.result.uspsData.standardizedAddress.zipCode
      let googleZip
      data.result.address.addressComponents.map(x => {
        if (x.componentType === 'postal_code') {
          googleZip = x.componentName.text
        }
      })
      const confirmedZip = uspsZip || googleZip
      // check for questionable confirmation levels
      const verdicts = data.result.address.addressComponents
      const verdictArray = []
      let finalVerdict = 'confirmed'
      for (let i = 0; i < verdicts.length; i++) {
        if (
          verdicts[i].confirmationLevel === 'UNCONFIRMED_BUT_PLAUSIBLE' ||
          verdicts[i].confirmationLevel === 'UNCONFIRMED_AND_SUSPICIOUS'
        ) {
          verdictArray.push(verdicts[i])
        }
      }
      if (verdictArray.length) {
        finalVerdict = 'unconfirmed'
      }
      return [finalVerdict, confirmedZip]
    })
  }
}
