
import AccordionPanel from '@/components/common/AccordionPanel.vue'
export default {
  name: 'CheckoutAccordionContainer',
  components: {
    AccordionPanel
  },
  props: {
    blok: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      selectedPanel: 0,
      headerColors: {
        activeColor: this.active,
        inactiveColor: this.inactive,
        textActive: this.textActive,
        textInactive: this.textInactive
      },
      show: true
    }
  },
  computed: {
    selectedPID() {
      return this.blok.checkout_accordion_container[this.selectedPanel]
        .panel_pid.selected
    },
    active() {
      return this.blok.custom_colors
        ? this.blok.header_bg_active.color
        : '#67bc07'
    },
    inactive() {
      return this.blok.custom_colors
        ? this.blok.header_bg_inactive.color
        : '#ececec'
    },
    textActive() {
      return this.blok.custom_colors ? this.blok.text_active.color : '#ffffff'
    },
    textInactive() {
      return this.blok.custom_colors ? this.blok.text_inactive.color : '#000000'
    }
  },
  watch: {
    blok: function() {
      this.$bus.$emit('update-pid', this.selectedPID)
    }
  },
  beforeMount() {
    if (this.blok.action === 'show') {
      this.show = false
    }
    if (
      this.blok.permanent &&
      this.$store.getters['timer/expiredTimers'].find(
        id => id === this.blok._uid
      )
    ) {
      this.show = this.blok.action !== 'hide'
    }
  },
  mounted() {
    this.selectedPanel = Math.max(
      0,
      this.blok.checkout_accordion_container.findIndex(
        panel => panel.panel_pid.selected === this.blok.default_panel.selected
      )
    )
    if (this.show) {
      this.$bus.$emit('update-pid', this.selectedPID)
    }
    this.decideAddTimer()
  },
  methods: {
    decideAddTimer() {
      if (!!this.blok.timer && this.blok.timer.timeUnit !== 'disabled') {
        this.$store.dispatch('timer/addEvent', {
          deadline: {
            id: this.blok._uid,
            seconds:
              Number(this.blok.timer.minutes * 60) +
              Number(this.blok.timer.seconds),
            isDateTime: this.blok.timer.timeUnit === 'dateTime',
            dateTime: this.blok.timer.dateTime,
            permanent: this.blok.permanent,
            callback: () => {
              this.show = this.blok.action !== 'hide'
            }
          }
        })
      }
    }
  }
}
