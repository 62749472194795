
import { RichContent } from '@/modules/RichContent'
import { mapGetters } from 'vuex'
import { compileStyles } from '@/modules/compileStyles'
export default {
  name: 'Paragraph',
  components: {},
  props: {
    blok: {
      required: true,
      type: Object
    },
    pidPassed: {
      required: false,
      type: Number,
      default: null
    }
  },
  data() {
    return {
      show: true
    }
  },
  computed: {
    richContent: function() {
      const productId = this.pid
      return RichContent(this.blok.paragraph_content.content, {
        customer: this.$store.getters.customer(),
        pricing: this.$store.getters.pricing(productId),
        timer: this.$store.getters['timer/events'][this.blok._uid] || {}
      })
    },
    pid: function() {
      return this.pidPassed ? this.pidPassed : this.blok.product_id.selected
    },
    style() {
      return compileStyles(this.blok.style || {}, this.$vuetify.breakpoint.name)
    }
  },
  beforeMount() {
    if (this.blok.action === 'show') {
      this.show = false
    }
    if (
      this.blok.permanent &&
      this.$store.getters['timer/expiredTimers'].find(
        id => id === this.blok._uid
      )
    ) {
      this.show = this.blok.action !== 'hide'
    }
  },
  mounted() {
    this.decideAddTimer()
  },
  methods: {
    // look for other instances of this time once I figure it out and correct those as well
    decideAddTimer() {
      if (!!this.blok.timer && this.blok.timer.timeUnit !== 'disabled') {
        this.$store.dispatch('timer/addEvent', {
          deadline: {
            id: this.blok._uid,
            seconds:
              Number(this.blok.timer.minutes * 60) +
              Number(this.blok.timer.seconds),
            isDateTime: this.blok.timer.timeUnit === 'dateTime',
            dateTime: this.blok.timer.dateTime,
            permanent: this.blok.permanent,
            callback: () => {
              this.show = this.blok.action !== 'hide'
            }
          }
        })
      }
    }
  },
  ...mapGetters(['pricing', 'customer'])
}
