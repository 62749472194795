
import { mapGetters } from 'vuex'
export default {
  name: 'PriceInfo',
  props: {
    blok: {
      required: true,
      type: Object
    },
    quantity: {
      required: true,
      type: Number
    },
    pid: {
      required: true,
      type: Number
    },
    state: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      hasPayments: false,
      numberOfPayments: 1,
      paymentPrice: 0,
      taxTotal: 0,
      withTaxTotal: 0,
      discounts: 0,
      overridePrice: 0,
      showDiscount: false,
      selectedPid: null,
      taxEstimateDisclaimer:
        'Tax shown here is estimated based on State and Postal Code.'
    }
  },
  computed: {
    ...mapGetters(['offer', 'pricing']),
    extraShipping() {
      if (this.offer(this.selectedPid).pricing.displayPrice > 50) {
        return 175
      } else {
        return 49
      }
    },
    paymentVerbiage() {
      return this.numberOfPayments === 1 ? 'payment' : 'payments'
    }
  },
  mounted() {
    this.paymentHandler(this.pid)
    this.$bus.$on('update-pid', pid => {
      const payInfo = this.offer(pid)
      this.hasPayments = payInfo.payments.paymentPlanFor
      this.numberOfPayments = payInfo.payments.numberOfPayments - 1
      this.paymentPrice = payInfo.pricing.displayPrice
      this.overridePrice = payInfo.pricing.overridePrice
      this.paymentHandler(pid)
    })
    this.$bus.$on('taxEstimate', ({ total, tax }) => {
      if (!total || !tax) {
        this.withTaxTotal = this.taxTotal = 0
      }
      if (!tax) {
        this.withTaxTotal = Number(total)
        return
      }
      this.withTaxTotal = Number(total.replace(',', ''))
      this.taxTotal = Number(tax.total.replace(',', '')) || 0
    })
    this.$bus.$on('clear-tax', () => {
      this.withTaxTotal = 0
      this.taxTotal = 0
    })
    this.$bus.$on('showDiscount', result => {
      if (this.showDiscount === true && 'discounts' in result) {
        this.discounts = result.discounts[0].total
        this.$bus.$emit('updateGPM', this.discounts)
      } else {
        this.showDiscount = false
        this.$bus.$emit('clear-discountGPM')
      }
    })
    this.$bus.$on('update-coupon', value => {
      this.showDiscount = value.promoCode && value.promoCode !== 'nonvalidCode'
    })
    this.$bus.$on('decline-coupon', () => {
      this.clearCoupon()
    })
  },
  beforeDestroy() {
    this.$bus.$off('showDiscount')
    this.$bus.$off('taxEstimate')
    this.$bus.$off('update-coupon')
    this.$bus.$off('decline-coupon', this.clearCoupon)
    this.$bus.$off('clear-tax')
    this.$bus.$off('update-pid', this.paymentHandler)
  },
  methods: {
    paymentHandler(pid) {
      const payInfo = this.offer(pid)
      this.selectedPid = pid
      this.hasPayments = payInfo.payments.paymentPlanFor
      this.numberOfPayments = payInfo.payments.numberOfPayments - 1
      this.paymentPrice = payInfo.pricing.displayPrice
      this.overridePrice = payInfo.pricing.overridePrice
    },
    clearCoupon() {
      this.showDiscount = false
      this.discounts = 0
    }
  }
}
