
import { RichContent } from '@/modules/RichContent'
import { mapGetters } from 'vuex'
import components from '@/components'
export default {
  name: 'SMSSignup',
  components,
  props: {
    blok: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      checkboxSMSSignup: false,
      smsLabel: this.blok.smsLabel.content
    }
  },
  computed: {
    richContent: function() {
      return RichContent(this.blok.paragraph.content, {
        customer: this.$store.getters.customer(),
        pricing: this.$store.getters.pricing(this.productId)
      })
    },
    richContentSMS: function() {
      return RichContent(this.blok.smsLabel.content, {
        customer: this.$store.getters.customer(),
        pricing: this.$store.getters.pricing(this.productId)
      })
    }
  },
  methods: {
    approveSMS() {
      if (this.checkboxSMSSignup === true) {
        this.$bus.$emit('accept-SMS', true)
      } else {
        this.$bus.$emit('decline-SMS', false)
      }
    }
  },
  ...mapGetters(['pricing', 'customer'])
}
