export default {
  mounted() {
    if (window.location.search.includes('_storyblok')) {
      // load the bridge only inside of Storyblok
      this.loadBridge()
    }
  },
  methods: {
    loadBridge() {
      const existingScript = document.getElementById('storyblokBridge')
      if (!existingScript) {
        const script = document.createElement('script')
        script.src = '//app.storyblok.com/f/storyblok-v2-latest.js'
        script.id = 'storyblokBridge'
        document.body.appendChild(script)
        script.onload = () => {
          this.initBridge()
        }
      } else {
        this.initBridge()
      }
    },
    initBridge() {
      const { StoryblokBridge } = window
      const storyblokInstance = new StoryblokBridge()
      storyblokInstance.on('input', event => {
        if (event.action === 'input') {
          const story = this.$store.getters.story
          // Inject content on the input event
          if (event.story.id === story.id) {
            this.$store.commit('enterStory', event.story)
          }
        } else if (!event.slugChanged) {
          // Reload the page on save events
          window.location.reload()
        }
      })
    }
  }
}
