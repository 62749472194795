export default {
  // ****************************************
  //
  // Base Components
  //
  // ****************************************
  AppFooter: () =>
    import(
      /* webpackChunkName: "base-components" */
      /* webpackMode: "eager" */
      '@/components/layout/AppFooter'
    ),
  AppHeader: () =>
    import(
      /* webpackChunkName: "base-components" */
      /* webpackMode: "eager" */
      '@/components/layout/AppHeader'
    ),
  Grid: () =>
    import(
      /* webpackChunkName: "base-components" */
      /* webpackMode: "eager" */
      '@/components/layout/grid/Grid'
    ),
  Paragraph: () =>
    import(
      /* webpackChunkName: "base-components" */
      /* webpackMode: "eager" */
      '@/components/common/Paragraph'
    ),
  SectionContainer: () =>
    import(
      /* webpackChunkName: "base-components" */
      /* webpackMode: "eager" */
      '@/components/layout/grid/SectionContainer'
    ),
  // ****************************************
  //
  // Checkout-Specific Components
  //
  // ****************************************
  CheckoutAccordionContainer: () =>
    import(
      /* webpackChunkName: "checkout-components" */
      /* webpackMode: "eager" */
      '@/components/common/CheckoutAccordionContainer'
    ),
  CheckoutAccordionContainerV2: () =>
    import(
      /* webpackChunkName: "checkout-components" */
      /* webpackMode: "eager" */
      '@/components/common/CheckoutAccordionContainerV2'
    ),
  CheckoutForm: () =>
    import(
      /* webpackChunkName: "checkout-components" */
      /* webpackMode: "eager" */
      '@/components/inactive/checkout/CheckoutForm'
    ),
  CheckoutFormRedux: () =>
    import(
      /* webpackChunkName: "checkout-components" */
      /* webpackMode: "eager" */
      '@/components/common/checkoutRedux/CheckoutFormRedux'
    ),
  CheckoutFormReduxBilling: () =>
    import(
      /* webpackChunkName: "checkout-components" */
      /* webpackMode: "eager" */
      '@/components/common/checkoutRedux/CheckoutFormReduxBilling'
    ),
  SocialProof: () =>
    import(
      /* webpackChunkName: "checkout-components" */
      /* webpackMode: "lazy" */
      '@/components/common/SocialProof'
    ),
  SoldOut: () =>
    import(
      /* webpackChunkName: "checkout-components" */
      /* webpackMode: "lazy" */
      '@/components/common/SoldOut'
    ),
  // ****************************************
  //
  // OTO-Specific Components
  //
  // ****************************************
  BreadCrumb: () =>
    import(
      /* webpackChunkName: "oto-components" */
      /* webpackMode: "lazy" */
      '@/components/common/BreadCrumb'
    ),
  StopBanner: () =>
    import(
      /* webpackChunkName: "oto-components" */
      /* webpackMode: "lazy" */
      '@/components/common/StopBanner'
    ),
  // ****************************************
  //
  // Thank You Page-Specific Components
  //
  // ****************************************
  DownloadTiles: () =>
    import(
      /* webpackChunkName: "typ-components" */
      /* webpackMode: "lazy" */
      '@/components/common/DownloadTiles'
    ),
  MainOffer: () =>
    import(
      /* webpackChunkName: "typ-components" */
      /* webpackMode: "lazy" */
      '@/components/common/MainOffer'
    ),
  OrderBreakdown: () =>
    import(
      /* webpackChunkName: "typ-components" */
      /* webpackMode: "lazy" */
      '@/components/common/OrderBreakdown'
    ),
  TYPTiles: () =>
    import(
      /* webpackChunkName: "typ-components" */
      /* webpackMode: "lazy" */
      '@/components/common/TYPTiles'
    ),
  TYPTilesTile: () =>
    import(
      /* webpackChunkName: "typ-components" */
      /* webpackMode: "lazy" */
      '@/components/common/TYPTilesTile'
    ),
  // ****************************************
  //
  // Individual Components
  //
  // ****************************************
  Dialog: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/common/Dialog'
    ),
  PaymentResubmitDialog: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/common/PaymentResubmitDialog'
    ),
  AccordionPanel: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/common/AccordionPanel'
    ),
  SplitTestContainer: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/splitTesting/SplitTestContainer'
    ),
  TestVariant: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/splitTesting/TestVariant'
    ),
  AccordionPanelV2: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/common/AccordionPanelV2'
    ),
  Audio: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/common/Audio/Audio'
    ),
  CountdownTimer: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/common/countdowntimer/CountdownTimer'
    ),
  ExpansionPanel: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/common/ExpansionPanel'
    ),
  Guarantee: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/common/Guarantee'
    ),
  Picture: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/common/Picture'
    ),
  ImageBlok: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/common/ImageBlok'
    ),
  ImageCard: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/common/ImageCard'
    ),
  ImageCardGrid: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/common/ImageCardGrid'
    ),
  List: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/common/List'
    ),
  NoThanks: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/common/NoThanks'
    ),
  OrderCTA: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/common/OrderCTA'
    ),
  OrderCTAPayment: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/common/OrderCTAPayment'
    ),
  PaymentsModal: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/common/PaymentsModal'
    ),
  PriceList: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/common/pricelist/PriceList'
    ),
  ProductShowcase: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/common/productshowcase/ProductShowcase'
    ),
  ProductShowcaseItem: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/common/productshowcase/ProductShowcaseItem'
    ),
  Redirect: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/common/Redirect'
    ),
  References: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/common/References'
    ),
  ReturnToTopButton: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/common/ReturnToTopButton'
    ),
  SecurityBadges: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/common/SecurityBadges'
    ),
  SharedContent: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/common/SharedContent'
    ),
  Testimonial: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/common/Testimonial'
    ),
  Video: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/common/video/Video'
    ),
  Vidalytics: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/common/video/Vidalytics'
    ),
  SMSOptIn: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/common/SMSOptIn'
    ),
  Carousel: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/common/carousel/Carousel'
    ),
  Preloader: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/common/Preloader'
    ),
  Chip: () =>
    import(
      /* webpackMode: "lazy" */
      '@/components/common/Chip'
    )
}
