
import components from '@/components'

export default {
  name: 'AccordionPanel',
  components,
  props: {
    blok: {
      required: true,
      type: Object
    },
    colors: {
      required: true,
      type: Object
    },
    active: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      chip: this.shipping
    }
  },
  computed: {
    shipping() {
      return this.blok.free_shipping
    }
  },
  methods: {
    selectedPid(pid) {
      this.$bus.$emit('update-pid', parseInt(pid))
    }
  }
}
