
import components from '@/components'
import { mapGetters } from 'vuex'
export default {
  name: 'Page',
  components,
  props: {
    blok: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapGetters(['inEditor'])
  },
  beforeMount() {
    if (
      this.blok.permanent &&
      this.$store.getters['timer/expiredTimers'].find(
        id => id === this.blok._uid
      )
    ) {
      this.redirect()
    }
  },
  mounted() {
    this.decideAddTimer()
    setTimeout(() => {
      this.blok.Use_preloader = ''
    }, 3000)
  },
  beforeDestroy() {
    this.$bus.$off('offerDeclined', this.declineOffer)
  },
  created() {
    if (
      Object.prototype.hasOwnProperty.call(this.blok, 'footer') &&
      this.blok.footer.length > 0
    ) {
      this.$bus.$emit('loadFooter', this.blok.footer[0].content)
    }
    if (
      Object.prototype.hasOwnProperty.call(this.blok, 'header') &&
      this.blok.header.length > 0
    ) {
      this.$bus.$emit('loadHeader', this.blok.header[0].content)
    }
  },
  methods: {
    declineOffer() {
      this.$store.dispatch('unsetEcommerce').then(() => {
        this.$store.dispatch('sendGTMEvent', { event: 'Offer Declined' })
      })
    },
    decideAddTimer() {
      if (!!this.blok.timer && this.blok.timer.timeUnit !== 'disabled') {
        this.$store.dispatch('timer/addEvent', {
          deadline: {
            id: this.blok._uid,
            seconds:
              Number(this.blok.timer.minutes * 60) +
              Number(this.blok.timer.seconds),
            isDateTime: this.blok.timer.timeUnit === 'dateTime',
            dateTime: this.blok.timer.dateTime,
            permanent: this.blok.permanent,
            callback: () => {
              this.redirect()
            }
          }
        })
      }
    },
    redirect() {
      if (this.inEditor) {
        return
      }
      if (this.blok.redirect_url.linktype === 'url') {
        window.location = this.blok.redirect_url.url
      } else {
        this.$router.push('/' + this.blok.redirect_url.cached_url)
      }
    }
  },
  head() {
    const metaData = this.blok.meta || {}
    const tagArray = []
    Object.entries(metaData).forEach(([key, value]) => {
      if (['_uid', 'plugin'].includes(key) || value === '') {
        return
      }
      value = value + ''
      key = key.replace('_', ':')
      if (key.includes(':')) {
        tagArray.push({ hid: key, property: key, content: value })
      } else {
        tagArray.push({ hid: key, name: key, content: value })
      }
    })
    return {
      title: metaData.title,
      titleTemplate:
        this.$config.brand === 'PHA'
          ? '%s - Patriot Health Alliance'
          : '%s - 4Patriots',
      meta: tagArray
    }
  }
}
