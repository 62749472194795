import { DateTime } from 'luxon'
import { unabbreviateState } from '~/modules/Territories'

/**
 *
 * @param string
 * @param store
 * @returns {string}
 * @constructor
 */
export function RichContent(string = '', store) {
  if (Object.prototype.hasOwnProperty.call(store, 'pricing')) {
    const pricing = store.pricing
    string = string
      .replace(/{{originalPrice}}/g, pricing.originalPrice)
      .replace(/{{price}}/g, pricing.price)
      .replace(/{{quantity}}/g, pricing.quantity)
      .replace(/{{taxRate}}/g, pricing.taxRate)
      .replace(/{{taxTotal}}/g, pricing.taxTotal)
      .replace(/{{shippingTotal}}/g, pricing.shippingTotal)
      .replace(/{{subTotal}}/g, pricing.subTotal)
      .replace(/{{grandTotal}}/g, pricing.grandTotal)
  }

  if (Object.prototype.hasOwnProperty.call(store, 'timer')) {
    const timer = store.timer
    string = string
      .replace(/{{[dD]{2}}}/g, timer.dd || 0)
      .replace(/{{[hH]{2}}}/g, timer.hh || '00')
      .replace(/{{[hH]}}/g, Number(timer.hh) || 0)
      .replace(/{{[mM]{2}}}/g, timer.mm || '00')
      .replace(/{{[mM]}}/g, Number(timer.mm) || 0)
      .replace(/{{[sS]{2}}}/g, timer.ss || '00')
      .replace(/{{[sS]}}/g, Number(timer.ss) || 0)
      .replace(
        /{{[sS]{3}}}/g,
        Number(timer.dd) * 24 * 60 * 60 +
          Number(timer.hh) * 60 * 60 +
          Number(timer.mm) * 60 +
          Number(timer.ss) || 0
      )
  }
  const customer = store.customer
  string = string
    .replace(/{{firstName}}/g, customer.firstName)
    .replace(/{{lastName}}/g, customer.lastName)
    .replace(/{{city}}/g, customer.billingCity)
    .replace(/{{state}}/g, unabbreviateState(customer.billingInfo.state))
    .replace(/{{country}}/g, customer.billingCountry)

    .replace(/{{today}}/g, DateTime.local().toLocaleString(DateTime.DATE_SHORT))
    .replace(/[{]+daysFromToday-(\d+)[}]+/, function(string, n) {
      return DateTime.local()
        .plus({ days: n })
        .toLocaleString(DateTime.DATE_SHORT)
    })

  return string
}
