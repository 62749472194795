
import AccordionPanelV2 from '@/components/common/AccordionPanelV2.vue'
export default {
  name: 'CheckoutAccordionContainerV2',
  components: {
    AccordionPanelV2
  },
  props: {
    blok: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      selectedPanel: 0
    }
  },
  computed: {
    selectedPID() {
      return this.blok.checkout_accordion_container[this.selectedPanel]
        .panel_pid.selected
    }
  },
  watch: {
    blok: function() {
      this.$bus.$emit('update-pid', this.selectedPID)
    }
  },
  mounted() {
    this.selectedPanel = this.blok.checkout_accordion_container.findIndex(
      panel => panel.panel_pid.selected === this.blok.default_panel.selected
    )
    this.$bus.$emit('update-pid', this.selectedPID)
  }
}
