
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { veeValidator } from '@/mixins/veeValidator'
import { filterForExclusions } from '@/modules/Territories'
import { compileStyles } from '@/modules/compileStyles'
import { SmsSubscribe } from '@/modules/KlaviyoSmsConsent'
import AddressForm from './AddressForm'
import CreditCardForm from './CreditCardForm'
import PriceInfo from './PriceInfo'
import SMSSignup from './SMSSignup'
export default {
  name: 'Checkout',
  components: {
    CreditCardForm,
    AddressForm,
    PriceInfo,
    SMSSignup,
    Discount: () => import('./Discount'),
    Quantity: () => import('./Quantity'),
    PaymentsOptions: () => import('./PaymentsOptions'),
    PaymentsModal: () => import('@/components/common/PaymentsModal')
  },
  mixins: [veeValidator],
  props: {
    blok: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      form: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        billingInfo: {
          address: '',
          city: '',
          country: 'US',
          state: '',
          zip: ''
        },
        shippingInfo: {
          address: '',
          city: '',
          country: 'US',
          state: '',
          zip: ''
        },
        creditCardInfo: {
          number: '',
          expiresOnMonth: '',
          expiresOnYear: '',
          CVV2: ''
        },
        shippingAddressDifferent: false,
        quantity: 1,
        productId: parseInt(this.blok.product_id.selected),
        promoCode: '',
        smsConsent: false,
        smsPageName: this.blok.keyword.keyword || 'NSPCOP'
      },
      show: true,
      shipToCanada: false,
      loading: false,
      paymentsFullPid: 0,
      hasPayments: false,
      completelySoldOut: false,
      modal: { show: false },
      discountGPM: null,
      offerPair: [],
      buttonDisabled: false,
      blotoutShipping: false,
      blotoutInit: false
    }
  },
  computed: {
    customTerritories() {
      const exclusions = this.offer(this.form.productId).shipping.exclusions
      return filterForExclusions(exclusions)
    },
    customValidators() {
      const exclusions = this.offer(this.form.productId).shipping.exclusions
      return exclusions.includes('poBox') ? { address: 'pobox' } : {}
    },
    checkPayments() {
      return pid => {
        let hasPayments = false
        this.offers.forEach(offer => {
          if (
            (offer.payments.paymentPlanFor === parseInt(pid) &&
              offer.pricing.isPaymentPlan) ||
            (offer.product.id === parseInt(pid) && offer.pricing.isPaymentPlan)
          ) {
            hasPayments = true
          }
        })
        return hasPayments
      }
    },
    isPaymentPlan() {
      return this.offer(this.form.productId).pricing.isPaymentPlan
    },
    discountCodes() {
      return {
        fullPrice: this.blok.DiscountCode,
        payments: this.blok.payments_discount_code
      }
    },
    style() {
      return compileStyles(this.blok.style || {}, this.$vuetify.breakpoint.name)
    },
    // determine presence of expiredTimer/permanent timer
    expiredTimerShow() {
      return this.$store.getters['timer/expiredTimers'].find(
        id => id === this.blok._uid
      )
    },
    ...mapGetters([
      'offer',
      'offers',
      'pricing',
      'busy',
      'confirmedPurchase',
      'paymentError'
    ])
  },
  beforeMount() {
    if (this.blok.action === 'show') {
      this.show = false
    }
    if (
      this.blok.permanent &&
      this.$store.getters['timer/expiredTimers'].find(
        id => id === this.blok._uid
      )
    ) {
      this.show = this.blok.action !== 'hide'
    }
  },
  mounted() {
    this.updateOfferPair()
    this.decideAddTimer()
    this.$bus.$on('update-pid', pid => {
      this.form.productId = pid.toString()
      if (this.isPaymentPlan) {
        this.$bus.$emit('hasPayments')
      } else {
        this.$bus.$emit('hasNoPayments')
      }
      this.$bus.$emit('reset-quantity')
      if (this.show) {
        this.updateOfferPair()
      }
      if (
        this.form.promoCode &&
        this.form.promoCode !== 'nonvalidCode' &&
        this.show
      ) {
        if (this.show) {
          this.$bus.$emit('change-coupon', pid)
        }
      } else if (this.form.promoCode === 'nonvalidCode' && this.show) {
        this.form.promoCode = ''
      }
      if (this.show) {
        setTimeout(() => {
          this.$bus.$emit('update-payment-options', pid)
        }, 100)
      }
      if (this.show) {
        this.updatePidToString()
      }
    })
    this.$bus.$on('set-coupon', () => {
      if (this.show) {
        this.form.promoCode = this.offer(this.form.productId).pricing
          .isPaymentPlan
          ? this.blok.payments_discount_code
          : this.blok.DiscountCode
        this.$bus.$emit('update-coupon', {
          promoCode: this.form.promoCode,
          pid: this.form.productId
        })
      }
    })
    this.$bus.$on('change-coupon', pid => {
      if (this.show) {
        this.form.promoCode =
          this.offer(this.form.productId).pricing.isPaymentPlan &&
          this.blok.payments_discount_code
            ? this.blok.payments_discount_code
            : this.offer(this.form.productId).pricing.isPaymentPlan &&
              !this.blok.payments_discount_code
            ? 'nonvalidCode'
            : this.blok.DiscountCode
        this.$bus.$emit('update-coupon', {
          promoCode: this.form.promoCode,
          pid
        })
      }
    })
    this.$bus.$on('decline-coupon', () => {
      if (this.show) {
        this.clearCoupon()
      }
    })
    this.$bus.$on('clear-discountGPM', () => {
      if (this.show) {
        this.discountGPM = null
      }
    })
    this.$bus.$on('update-quantity', quantity => {
      if (this.show) {
        this.form.quantity = quantity
      }
    })
    this.$bus.$on('completelySoldOut', () => {
      if (this.show) {
        this.completelySoldOut = true
      }
    })
    this.$bus.$on('accept-SMS', () => {
      this.form.smsConsent = true
      if (this.show) {
        this.acceptSMS = true
      }
    })
    this.$bus.$on('decline-SMS', () => {
      this.form.smsConsent = false
      if (this.show) {
        this.acceptSMS = false
      }
    })
    this.$bus.$on('updateGPM', discount => {
      if (this.show) {
        this.discountGPM =
          this.$store.getters.offer(this.form.productId).pricing.metric1 -
          discount
      }
    })
    this.$bus.$on('google-disable', () => {
      this.buttonDisabled = true
    })
    this.$bus.$on('google-corrected', () => {
      this.buttonDisabled = false
    })

    const kaScript = document.createElement('script')
    const fullSessionId = this.$store.getters.sessionId
    const kountUrl = this.$config.kountDataCollector
    kaScript.setAttribute('src', kountUrl + fullSessionId)
    document.head.appendChild(kaScript)

    window.addEventListener('keydown', this.blotoutEvent, { once: true })
  },
  beforeDestroy() {
    this.$bus.$off('update-pid', this.updatePid)
    this.$bus.$off('set-coupon')
    this.$bus.$off('change-coupon')
    this.$bus.$off('decline-coupon', this.clearCoupon)
    this.$bus.$off('update-quantity')
    this.$bus.$off('completelySoldOut')
    this.$bus.$off('updateGPM')
    this.$bus.$off('clear-discountGPM')
    window.removeEventListener('keydown', this.blotoutEvent)
  },
  methods: {
    clearCoupon() {
      this.form.promoCode = ''
    },
    updateOfferPair() {
      this.offerPair = []
      this.offers.forEach(offer => {
        if (
          offer.product.id === parseInt(this.form.productId) ||
          (offer.payments.paymentPlanFor === parseInt(this.form.productId) &&
            offer.pricing.isPaymentPlan)
        ) {
          this.offerPair.push(offer)
        }
      })
    },
    decideAddTimer() {
      if (!!this.blok.timer && this.blok.timer.timeUnit !== 'disabled') {
        this.$store.dispatch('timer/addEvent', {
          deadline: {
            id: this.blok._uid,
            seconds:
              Number(this.blok.timer.minutes * 60) +
              Number(this.blok.timer.seconds),
            isDateTime: this.blok.timer.timeUnit === 'dateTime',
            dateTime: this.blok.timer.dateTime,
            permanent: this.blok.permanent,
            callback: () => {
              this.show = this.blok.action !== 'hide'
              if (this.offer(this.form.productId).pricing.isPaymentPlan) {
                this.offerPair.forEach(offer => {
                  if (offer.pricing.isPaymentPlan) {
                    this.$bus.$emit('update-pid', offer.product.id)
                  }
                })
              } else {
                this.offerPair.forEach(offer => {
                  if (!offer.pricing.isPaymentPlan) {
                    this.$bus.$emit('update-pid', offer.product.id)
                  }
                })
              }
            }
          }
        })
      }
    },
    updatePidToString(pid) {
      const payInfo = this.offer(pid)
      this.hasPayments = payInfo.payments.paymentPlanFor
      this.numberOfPayments = payInfo.payments.numberOfPayments - 1
      this.paymentPrice = payInfo.pricing.displayPrice
    },
    callToAction() {
      if (this.offer(this.form.productId).pricing.isPaymentPlan) {
        this.$validator.validateAll().then(success => {
          if (success) {
            this.openMyDialog()
          } else {
            try {
              this.$vuetify.goTo('.error--text', { offset: 50 })
            } catch (e) {}
          }
        })
      } else {
        this.submitOrder()
      }
      // eslint-disable-next-line no-undef
      const client = new ka.ClientSDK()
      client.setupCallback({
        'collect-begin': function(params) {
          const checkoutForm = document.forms.checkoutForm
          const input = document.createElement('input')
          input.type = 'hidden'
          input.name = 'sessionId'
          input.value = params.MercSessId
          checkoutForm.appendChild(input)
        }
      })
      client.autoLoadEvents()
    },
    submitOrder(TILAAgreement) {
      this.$validator.validateAll().then(success => {
        if (success) {
          this.form.creditCardInfo.number = this.form.creditCardInfo.number.replace(
            /-/g,
            ''
          )
          if (this.offer(this.form.productId).pricing.isPaymentPlan) {
            this.modal.show = false
          }
          if (typeof TILAAgreement !== 'undefined') {
            Object.assign(this.form, TILAAgreement)
          }

          if (!this.form.shippingAddressDifferent) {
            this.form.shippingInfo = this.form.billingInfo
          }
          if (this.acceptSMS === true) {
            SmsSubscribe(
              this.form.email,
              this.form.phone,
              this.form.smsConsent,
              this.form.smsPageName,
              this.$config.klaviyoCompanyId,
              this.$config.klaviyoListId
            )
          }
          if (this.discountGPM) {
            this.$store.getters.offer(
              this.form.productId
            ).pricing.metric1 = this.discountGPM
          }

          this.setCustomer(this.form)

          this.purchase(this.form).then(({ errorCode }) => {
            if (errorCode) {
              this.$vuetify.goTo('.v-alert__content', { offset: 50 })
              if (errorCode === 800) {
                this.form.creditCardInfo.number = ''
                this.form.creditCardInfo.expiresOnMonth = ''
                this.form.creditCardInfo.expiresOnYear = ''
                this.form.creditCardInfo.CVV2 = ''
              } else if (errorCode === 342) {
                this.form.creditCardInfo.expiresOnMonth = ''
                this.form.creditCardInfo.expiresOnYear = ''
              }
            }
          })
        } else {
          this.$vuetify.goTo('.error--text', { offset: 50 })
        }
      })
    },
    updatePid(value) {
      this.form.productId = value.toString()
    },
    openMyDialog() {
      if (
        // triggers modal on 4+ payments, needs to be explicit for legal reasons
        this.offer(this.form.productId).payments.numberOfPayments >= 4 ||
        // triggers modal on > 4 payments and if there are items in the form array
        (this.offer(this.form.productId).payments.numberOfPayments < 4 &&
          Array.isArray(
            this.offer(this.form.productId).payments.paymentModalCheckboxes
          ) &&
          this.offer(this.form.productId).payments.paymentModalCheckboxes
            .length >= 1)
      ) {
        this.modal.show = true
      } else {
        this.submitOrder()
      }
    },
    ...mapActions({
      purchase: 'purchase'
    }),
    ...mapMutations({
      setCustomer: 'setCustomer'
    }),
    getBlotoutPayload() {
      const category = this.$store.getters.route.productLine
      const url = this.$store.getters['analytics/url'].base
      const offer = this.$store.getters.offer(this.form.productId)
      return {
        currency: 'USD',
        checkoutUrl: url,
        contents: [
          {
            id: offer.product.id,
            quantity: 1,
            item_price: offer.pricing.displayPrice,
            title: offer.name,
            category,
            url
          }
        ]
      }
    },
    blotoutEvent() {
      if (this.show && !this.blotoutInit) {
        this.$blotout.sendEvent('InitiateCheckout', this.getBlotoutPayload())
        this.blotoutInit = true
      }
      // emits from address form
      this.$bus.$on('blotout-address', () => {
        if (this.show && !this.blotoutShipping) {
          this.$blotout.sendEvent('AddShippingInfo', this.getBlotoutPayload())
          this.blotoutShipping = true
        }
      })
    }
  }
}
