const axios = require('axios')
export function SmsSubscribe(
  email,
  phone,
  smsConsent,
  smsPageName,
  companyId,
  listId
) {
  phone = phone.replace(/\D+/g, '')
  const options = {
    method: 'POST',
    url: 'https://a.klaviyo.com/client/subscriptions/',
    params: { company_id: companyId },
    headers: {
      revision: '2023-08-15',
      'content-type': 'application/json'
    },
    data: {
      data: {
        type: 'subscription',
        attributes: {
          custom_source: smsPageName,
          profile: {
            data: {
              type: 'profile',
              attributes: {
                email: email,
                phone_number: '+1' + phone,
                properties: {
                  'SMS Opt-In Source': smsPageName,
                  sms_consent: smsConsent
                }
              }
            }
          }
        },
        relationships: {
          list: {
            data: {
              type: 'list',
              id: listId
            }
          }
        }
      }
    }
  }
  return new Promise((resolve, reject) => {
    axios
      .request(options)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        console.error('Error in Axios request:', error)
        reject(error)
      })
  })
}
