
import { layout } from '@/mixins/customLayout'
import { compileStyles } from '@/modules/compileStyles'
export default {
  name: 'Grid',
  components: {
    GridItem: () => import('@/components/layout/grid/GridItem'),
    GridSpacer: () => import('@/components/layout/grid/GridSpacer')
  },
  mixins: [layout],
  props: {
    blok: {
      required: true,
      type: Object
    },
    pidPassed: {
      required: false,
      type: Number,
      default: null
    },
    shipping: {
      required: false,
      type: Boolean
    }
  },
  computed: {
    layoutAttributes() {
      if (!this.blok.gutterSize) {
        return { class: 'mx-0' }
      }
      return { [this.blok.gutterSize]: this.blok.gutterSize !== 'full' }
    },
    computedClasses: function() {
      return this.constructClassObj(this.blok.custom_layout, this)
    },
    style() {
      return compileStyles(this.blok.style || {}, this.$vuetify.breakpoint.name)
    }
  }
}
