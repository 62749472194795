
import { RichContent } from '@/modules/RichContent'
import components from '@/components'

export default {
  name: 'AccordionPanelV2',
  components,
  props: {
    blok: {
      required: true,
      type: Object
    }
  },
  data() {
    return {}
  },
  computed: {
    setBurst() {
      switch (this.blok.shipping_burst_selection) {
        case 'free-sh':
          return 'https://products.patriothealthalliance.com/images/free-shipping-badge.png'
        case 'best-deal':
          return 'https://products.patriothealthalliance.com/images/best-deal-burst.png'
        default:
          return 'https://products.patriothealthalliance.com/images/free-shipping-badge.png'
      }
    },
    richPriceContent: function() {
      let productId = null
      if (
        Object.prototype.hasOwnProperty.call(this.blok, 'panel_pid') &&
        this.blok.panel_pid.selected !== ''
      ) {
        productId = this.blok.panel_pid.selected
      }
      return RichContent(this.blok.pricing_slot.content, {
        customer: this.$store.getters.customer(),
        pricing: this.$store.getters.pricing(productId)
      })
    },
    richProductContent: function() {
      return RichContent(this.blok.product_slot.content, {
        customer: this.$store.getters.customer(),
        pricing: this.$store.getters.pricing(this.$store.state.productId)
      })
    }
  },
  methods: {
    selectedPid(pid) {
      this.$bus.$emit('update-pid', parseInt(pid))
    }
  }
}
