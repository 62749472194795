const axios = require('axios')

const cache = new Map()

export function estimate(order, taxEnvURL) {
  const cachedValue = cache.get(JSON.stringify(order))
  if (cachedValue) {
    return new Promise(resolve => resolve(cachedValue))
  }
  return axios
    .post(taxEnvURL + '/estimate', order)
    .then(({ data }) => {
      if (data.success) {
        cache.set(JSON.stringify(order), data.response.data)
      }
      return data.response.data
    })
    .catch(error => console.error({ ErrorMessage: error }))
}
