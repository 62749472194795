
import { states, countryLabels } from '@/modules/Territories'
import { mapGetters } from 'vuex'
import { estimate } from '../../../modules/TaxEstimate'
import GPlaces from '../../../modules/GPlaces'

export default {
  name: 'AddressForm',
  props: {
    selectedPid: {
      required: true,
      type: Number
    },
    value: {
      required: true,
      type: Object
    },
    type: {
      required: true,
      type: String
    },
    customTerritories: {
      required: false,
      type: Object,
      default: null
    },
    customValidators: {
      required: false,
      type: Object,
      default: () => {
        return {}
      }
    },
    promoCode: {
      required: false,
      type: String,
      default: null
    },
    blok: {
      required: true,
      type: Object
    },
    details: {
      required: false,
      type: String,
      default: null
    }
  },
  inject: {
    $validator: 'parentValidator'
  },
  data() {
    return {
      form: {
        productId: parseInt(this.blok.product_id.selected),
        promoCode: null,
        useShippingAddress: false,
        email: null
      },
      order: {
        company: '4P',
        product_id: '',
        quantity: 1,
        price: '',
        campaign_id: '',
        shipping_id: '',
        postal_code: '',
        country: '',
        coupon_code: '',
        email: ''
      },
      zipError: false,
      confirmedZip: '',
      zipErrorMsg: '',
      shipClear: true,
      billClear: true,
      lastChecked: ''
    }
  },
  computed: {
    countries: function() {
      const countries =
        this.customTerritories !== null
          ? Object.keys(this.customTerritories)
          : ['US', 'CA']

      const filteredCountries = []

      countries.forEach(country => {
        filteredCountries.push({
          value: country,
          text: countryLabels[country]
        })
      })

      return filteredCountries
    },
    states: function() {
      return this.customTerritories !== null
        ? this.customTerritories[this.value.country]
        : states[this.value.country]
    },
    fieldPrefix: function() {
      return this.type.charAt(0).toLowerCase() + this.type.slice(1)
    },
    ...mapGetters(['offer', 'pricing', 'shipping'])
  },
  mounted() {
    this.useShippingAddress = false
    this.mountedPid = this.selectedPid
    this.updatedQuantity = this.order.quantity
    this.$bus.$on('update-pid', pid => {
      this.mountedPid = pid
      this.form.productId = pid
      this.form.promoCode = this.promoCode
      if (!this.form.promoCode || this.form.promoCode === 'nonvalidCode') {
        this.getTaxEstimate()
      }
    })

    // listens for coupon option
    this.$bus.$on('update-coupon', ({ promoCode, pid }) => {
      if (promoCode === 'nonvalidCode') {
        this.$bus.$emit('uncheck-box')
      } else {
        this.form.promoCode = promoCode
        this.mountedPid = pid
        this.form.productId = pid
        this.getTaxEstimate()
      }
    })

    this.$bus.$on('decline-coupon', () => {
      this.form.promoCode = 'nonvalidCode'
      this.getTaxEstimate()
    })
    this.$bus.$on('update-quantity', quantity => {
      this.updatedQuantity = quantity
      this.getTaxEstimate()
    })
    // Google Places Autocomplete
    if (
      process.browser &&
      window.location.pathname.indexOf('checkout') !== -1 &&
      this.blok.turn_on_autocomplete
    ) {
      GPlaces.loadPlacesApi(this.$config.googlePlaces).then(() => {
        const inputId = this.type
        const autocomplete = GPlaces.initAutocomplete(inputId)
        // Google Maps pushes out event "place_changed" when address is selected
        // eslint-disable-next-line no-undef
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
          // get the address responses from Google and drop them into the form
          const place = autocomplete.getPlace()
          return this.onPlaceChanged(place)
        })
      })
    }
  },
  methods: {
    async getTaxEstimate() {
      const offer = this.offer(this.mountedPid)
      const order = {
        company: this.$config.brand,
        product_id: parseInt(this.mountedPid),
        quantity: this.updatedQuantity,
        price: this.$store.getters.pricing(this.mountedPid).price,
        overridePrice: this.$store.getters.pricing(this.mountedPid)
          .overridePrice,
        campaign_id: offer.campaignId,
        shipping_id:
          this.value.country === 'US'
            ? offer.shipping.domesticId
            : offer.shipping.internationalId,
        country: this.value.country
        // postal_code: this.value.zip || '10000',
        // state: this.value.state || 'XX'
      }
      // Update state and postal_code only if this.details is not 'billingIsDiff' (Checkout Redux Billing in use)
      if (this.details !== 'billingIsDiff') {
        order.state = this.value.state || 'XX'
        order.postal_code = this.value.zip || '10000'
      }
      // Set email to test email to return discount info if no email entered
      if (this.form.promoCode) {
        order.email = this.form.email || 'test@4patriots.com'
        order.coupon_code = this.form.promoCode
      }
      // Set Override Price in Funnel data as main price if Price is 0
      if (!order.price) {
        order.price = order.overridePrice
      }
      if (!this.form.promoCode && order.postal_code === '10000') {
        this.$bus.$emit('clear-tax')
        return
      }
      if (order.postal_code && order.state) {
        await estimate(order, this.$config.taxEstimate).then(res => {
          if (res) {
            this.$bus.$emit('taxEstimate', res)
            this.$bus.$emit('showDiscount', res)
          }
        })
      }
    },
    onPlaceChanged(place) {
      let address1 = ''
      let address2 = ''
      for (const component of place.address_components) {
        const componentType = component.types[0]
        switch (componentType) {
          case 'street_number': {
            address1 = `${component.long_name}${address1} `
            break
          }
          case 'route': {
            address1 += `${component.short_name}${address2}`
            break
          }
          case 'subpremise': {
            address2 += ` ${component.short_name}`
            break
          }
          case 'postal_code': {
            this.value.zip = component.long_name
            break
          }
          case 'locality': {
            this.value.city = component.long_name
            break
          }
          case 'administrative_area_level_1': {
            this.value.state = component.short_name
            break
          }
          case 'country': {
            this.value.country = component.short_name
          }
        }
        this.value.address = address1
      }
      this.preValidate()
    },
    preValidate() {
      if (
        !this.value.city ||
        !this.value.state ||
        !this.value.address ||
        !this.value.zip ||
        this.value.city.toLowerCase() === 'noship' ||
        this.value.address.toLowerCase() === 'noship'
      ) {
        return
      }
      const inputAddress = {
        regionCode: this.value.country,
        locality: this.value.city,
        administrativeArea: this.value.state,
        postalCode: this.value.zip,
        addressLines: [this.value.address]
      }
      // check if the address has already been validated
      const hash = JSON.stringify(inputAddress)
      if (hash === this.lastChecked) {
        return
      }
      this.lastChecked = hash
      this.googleValidate(inputAddress)
      // emit to checkout form to prevent double trigger on billing/shipping
      this.$bus.$emit('blotout-address')
    },
    async googleValidate(inputAddress) {
      const key = this.$config.googleAddressValidation
      const result = await GPlaces.validateAddress(key, inputAddress)
      // collect result and compose error message
      if (result) {
        this.confirmedZip = result[1]
        if (
          this.confirmedZip !== this.value.zip &&
          this.confirmedZip !== undefined
        ) {
          this.zipError = true
          this.$bus.$emit('google-disable')
          this.zipErrorMsg = `<span id="line1">This is not a valid zip code.</span>Did you mean <span id="zipText">${this.confirmedZip}</span>?`
        } else if (result[0] === 'unconfirmed') {
          this.zipError = true
          this.$bus.$emit('google-disable')
          this.zipErrorMsg = 'Zip code is not confirmed for this address.'
        } else {
          // set no error or clear errors fixed manually (without click function)
          this.zipError = false
          if (this.type === 'Billing') this.billClear = true
          if (this.type === 'Shipping') this.shipClear = true
          this.getTaxEstimate()
          this.clearError()
        }
        // set errors on shipping or billing
        if (this.zipError && this.type === 'Shipping') {
          this.shipClear = false
        }
        if (this.zipError && this.type === 'Billing') {
          this.billClear = false
        }
      }
    },
    // click function to correct the zip code
    correctZip() {
      this.value.zip = this.confirmedZip
      if (this.zipError && this.type === 'Shipping') {
        this.zipErrorMsg = ''
        this.shipClear = true
      } else if (this.zipError && this.type === 'Billing') {
        this.zipErrorMsg = ''
        this.billClear = true
      }
      this.clearError()
    },
    clearError() {
      // clear error and enable submit button
      const shippingEl = document.getElementById('shipping')
      const shipText = shippingEl ? shippingEl.innerText : null
      const billingEl = document.getElementById('billing')
      const billText = billingEl ? billingEl.innerText : null
      if (
        (this.billClear && this.shipClear && (!shippingEl || !billingEl)) ||
        (this.billClear && !shipText) ||
        (this.shipClear && !billText)
      ) {
        this.$bus.$emit('google-corrected')
      }
    }
  }
}
