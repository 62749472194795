
import { layout } from '@/mixins/customLayout'
import { compileStyles } from '@/modules/compileStyles'
import components from '@/components'
export default {
  name: 'SectionContainer',
  components,
  mixins: [layout],
  props: {
    blok: {
      required: true,
      type: Object
    },
    pidPassed: {
      required: false,
      type: Number,
      default: null
    }
  },
  data() {
    return {
      show: false
    }
  },
  computed: {
    computedClasses: function() {
      const boundClasses = {
        sticky: this.blok.sticky,
        'full-height': this.blok.fill_height
      }
      return this.constructClassObj(this.blok.custom_layout, this, boundClasses)
    },
    style() {
      return compileStyles(this.blok.style || {}, this.$vuetify.breakpoint.name)
    },
    // determine presence of expiredTimer/permanent timer
    expiredTimerShow() {
      return !!this.$store.getters['timer/expiredTimers'].find(
        id => id === this.blok._uid
      )
    }
  },
  beforeMount() {
    if (this.blok.hidden_hybrid_content) {
      if (this.blok.pop_on_timer || !this.$vuetify.breakpoint.smAndDown) {
        this.$bus.$on('hiddenContentRevealExpired', () => (this.show = true))
        // if permanent timer is set and content should show on expiration
        this.show = this.expiredTimerShow
      }
    } else if (this.blok.action === 'show') {
      // if timer is set and content should show on expiration
      this.show = this.expiredTimerShow
    } else {
      // if permanent timer is set and content should hide on expiration
      this.show = !this.expiredTimerShow
    }
  },
  mounted() {
    this.decideAddTimer()
    if (this.$route.query.poptimer === 'false') {
      this.show = true
    }
  },
  beforeDestroy() {
    this.$bus.$off('hiddenContentRevealExpired')
  },
  methods: {
    decideAddTimer() {
      if (!!this.blok.timer && this.blok.timer.timeUnit !== 'disabled') {
        this.$store.dispatch('timer/addEvent', {
          deadline: {
            id: this.blok._uid,
            seconds:
              Number(this.blok.timer.minutes * 60) +
              Number(this.blok.timer.seconds),
            isDateTime: this.blok.timer.timeUnit === 'dateTime',
            dateTime: this.blok.timer.dateTime,
            permanent: this.blok.permanent,
            callback: () => {
              this.show = this.blok.action !== 'hide'
            }
          }
        })
      }
    }
  }
}
