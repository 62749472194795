export const veeValidator = {
  data() {
    return {
      validated: true,
      dictionary: {
        attributes: {
          email: 'E-mail Address',
          firstName: 'First Name',
          lastName: 'Last Name',
          phone: 'Phone Number',
          updatePhone: 'Phone Number',
          billingAddress: 'Billing Address',
          billingCity: 'Billing City',
          billingCountry: 'Billing Country',
          billingState: 'Billing State',
          billingZip: 'Billing Zip',
          shippingAddress: 'Shipping Address',
          shippingCity: 'Shipping City',
          shippingCountry: 'Shipping Country',
          shippingState: 'Shipping State',
          shippingZip: 'Shipping Zip',
          number: 'Credit Card Number',
          cardCVV2: 'CVV2',
          expiresOnMonth: 'Expiration Month',
          expiresOnYear: 'Expiration Year'
        },
        custom: {
          email: {
            required: 'A valid Email is required'
          },
          receiptOfTILADisclosure: {
            required: () => 'Please check to continue'
          },
          agreeToPayments: {
            required: () => 'Please check to continue'
          },
          permissionToContact: {
            required: () => 'Please check to continue'
          }
        }
      }
    }
  },
  $_veeValidate: {
    validator: 'new'
  },
  mounted() {
    this.$validator.localize('en', this.dictionary)

    this.$validator.extend('pobox', {
      getMessage(field, val) {
        return 'This product cannot be shipped to PO Boxes'
      },
      validate: (value, field) => {
        if (value === '') {
          return false
        }
        const pattern = /(((p[\s.\-/]?\s?[o\s]\s?[.-]?)\s?)|(post\s?(office\s)?))?\s?((box|bin|b\.?)\s?(num|number|#)?\s?\d+)/gim
        return !value.match(pattern)
      }
    })

    this.$validator.extend('phone', {
      getMessage(field, val) {
        return 'This is not a valid phone number'
      },
      validate: (value, field) => {
        if (value === '') {
          return false
        }
        const phoneRegex = new RegExp(
          /^\(?[2-9]\d{2}\)?[- .]?\d{3}[- .]?\d{4}$/gim
        )
        return phoneRegex.test(value)
      }
    })

    this.$validator.extend('zip', {
      getMessage(field, val) {
        return 'This is not a valid 5-digit zip code'
      },
      validate: (value, field) => {
        if (value === '') {
          return false
        }
        const zipRegex = new RegExp(/(^\d{5}$)/)
        return zipRegex.test(value)
      }
    })
  },
  provide() {
    return {
      parentValidator: this.$validator
    }
  },
  methods: {}
}
