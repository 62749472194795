export const layout = {
  data() {
    return {
      directions: {
        top: 't',
        left: 'l',
        right: 'r',
        bottom: 'b'
      },
      sizes: ['xs', 'sm', 'md', 'lg', 'xl'],
      layout: {
        padding: 'p',
        margin: 'm'
      },
      instance: null,
      existing: null,
      classOutput: {}
    }
  },
  methods: {
    setup(that, existing) {
      this.instance = that
      // Clear the output
      this.classOutput = {}
      // Set up any existing classes
      this.existing = existing || {}
    },
    constructClassObj(input, instance, existing) {
      // Set the instance we're working with
      this.setup(instance, existing)
      const types = Object.keys(this.layout)
      const directions = Object.keys(this.directions)
      // Loop through the custom layout object
      if (typeof input !== 'undefined') {
        Object.entries(input).forEach(([type, typeObj]) => {
          // If not a valid type (margin or padding), skip
          if (!types.includes(type)) return
          // New direction-prioritized way
          Object.entries(typeObj).forEach(([dir, dirObj]) => {
            // If not a valid direction, skip
            if (!directions.includes(dir)) return
            const direction = this.directions[dir]
            let keyString = ''
            Object.entries(dirObj).forEach(([view, value]) => {
              // If not an actual screen size, skip
              if (value === null || !this.sizes.includes(view)) return
              // Construct the class name / key
              const viewString = view !== 'xs' ? '-' + view : ''
              keyString =
                this.layout[type] + direction + viewString + '-' + value
              this.classOutput[keyString] = true
            })
          })
        })
      }
      // End by adding all existing from base component
      this.addExistingClasses()
      return this.classOutput
    },
    addExistingClasses() {
      if (this.existing === null) return
      this.classOutput = { ...this.classOutput, ...this.existing }
    }
  }
}
