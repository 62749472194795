export function compileStyles({ background, border }, device) {
  if (!background || !border) {
    return {}
  }
  // Sorting Breakpoints by device
  const breakpoint = {
    xl: 'default',
    lg: 'desktop',
    md: 'desktop',
    sm: 'tablet',
    xs: 'mobile'
  }[device]

  const {
    backgroundColor,
    backgroundSize,
    backgroundRepeat,
    backgroundPosition,
    backgroundOpacity,
    backgroundImage,
    borderStyle,
    borderColor,
    borderRadius,
    borderRadiusUnit,
    borderSize,
    borderSizeUnit
  } = {
    ...background.default,
    ...border.default,
    ...Object.fromEntries(
      // remove properties with nil values
      Object.entries(background[breakpoint]).filter(([_, v]) => !!v)
    ),
    ...Object.fromEntries(
      // remove properties with nil values
      Object.entries(border[breakpoint]).filter(([_, v]) => !!v)
    )
  }

  return {
    backgroundColor,
    backgroundImage: backgroundImage ? 'url("' + backgroundImage + '")' : '',
    backgroundSize,
    backgroundRepeat,
    backgroundPosition,
    opacity: (backgroundOpacity || 100) * 0.01,
    borderRadius: (borderRadius || 0) + borderRadiusUnit,
    borderWidth: (borderSize || 0) + borderSizeUnit,
    borderStyle,
    borderColor
  }
}
