
import components from '@/components'
export default {
  name: 'SharedContentType',
  components,
  props: {
    blok: {
      required: true,
      type: Object
    },
    pidPassed: {
      required: false,
      type: Number,
      default: null
    }
  }
}
