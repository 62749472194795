
import { months } from '@/modules/Territories'
export default {
  name: 'CreditCardForm',
  props: {
    value: {
      required: true,
      type: Object
    },
    blok: {
      required: true,
      type: Object
    }
  },
  inject: {
    $validator: 'parentValidator'
  },
  data() {
    return {
      validated: true,
      blotoutFired: false
    }
  },
  computed: {
    expirationYears: function() {
      let currentYear = new Date().getFullYear()
      const year = currentYear
      const years = []
      while (currentYear <= year + 10) {
        years.push({
          text: currentYear,
          value: currentYear.toString().substr(-2)
        })
        currentYear++
      }
      return years
    },
    expirationMonths: function() {
      const date = new Date()
      return months.map(m => {
        m.disabled =
          this.value.expiresOnYear ===
            date
              .getFullYear()
              .toString()
              .slice(-2) && +m.value <= date.getMonth()
        return m
      })
    }
  },
  methods: {
    blotoutEvent() {
      // Check if blotout has already fired or if a field is missing a value
      if (this.blotoutFired || !Object.values(this.value).every(Boolean)) return

      // Get event data
      const category = this.$store.getters.route.productLine
      const url = this.$store.getters['analytics/url'].base
      const offer = this.$store.getters.offer(
        this.$parent.$parent.form.productId
      )
      // Fire event and update blotoutFired
      this.$blotout.sendEvent('AddPaymentInfo', {
        currency: 'USD',
        contents: [
          {
            id: offer.product.id,
            quantity: 1,
            item_price: offer.pricing.displayPrice,
            title: offer.name,
            category,
            url
          }
        ]
      })
      this.blotoutFired = true
    }
  }
}
