export const states = {
  US: [
    { value: 'AL', text: 'Alabama' },
    { value: 'AK', text: 'Alaska' },
    { value: 'AZ', text: 'Arizona' },
    { value: 'AR', text: 'Arkansas' },
    { value: 'CA', text: 'California' },
    { value: 'CO', text: 'Colorado' },
    { value: 'CT', text: 'Connecticut' },
    { value: 'DE', text: 'Delaware' },
    { value: 'FL', text: 'Florida' },
    { value: 'GA', text: 'Georgia' },
    { value: 'HI', text: 'Hawaii' },
    { value: 'ID', text: 'Idaho' },
    { value: 'IL', text: 'Illinois' },
    { value: 'IN', text: 'Indiana' },
    { value: 'IA', text: 'Iowa' },
    { value: 'KS', text: 'Kansas' },
    { value: 'KY', text: 'Kentucky' },
    { value: 'LA', text: 'Louisiana' },
    { value: 'ME', text: 'Maine' },
    { value: 'MD', text: 'Maryland' },
    { value: 'MA', text: 'Massachusetts' },
    { value: 'MI', text: 'Michigan' },
    { value: 'MN', text: 'Minnesota' },
    { value: 'MS', text: 'Mississippi' },
    { value: 'MO', text: 'Missouri' },
    { value: 'MT', text: 'Montana' },
    { value: 'NE', text: 'Nebraska' },
    { value: 'NV', text: 'Nevada' },
    { value: 'NH', text: 'New Hampshire' },
    { value: 'NJ', text: 'New Jersey' },
    { value: 'NM', text: 'New Mexico' },
    { value: 'NY', text: 'New York' },
    { value: 'NC', text: 'North Carolina' },
    { value: 'ND', text: 'North Dakota' },
    { value: 'OH', text: 'Ohio' },
    { value: 'OK', text: 'Oklahoma' },
    { value: 'OR', text: 'Oregon' },
    { value: 'PA', text: 'Pennsylvania' },
    { value: 'RI', text: 'Rhode Island' },
    { value: 'SC', text: 'South Carolina' },
    { value: 'SD', text: 'South Dakota' },
    { value: 'TN', text: 'Tennessee' },
    { value: 'TX', text: 'Texas' },
    { value: 'UT', text: 'Utah' },
    { value: 'VT', text: 'Vermont' },
    { value: 'VA', text: 'Virginia' },
    { value: 'WA', text: 'Washington' },
    { value: 'DC', text: 'Washington DC' },
    { value: 'WV', text: 'West Virginia' },
    { value: 'WI', text: 'Wisconsin' },
    { value: 'WY', text: 'Wyoming' }
  ],
  CA: [
    { value: 'AB', text: 'Alberta' },
    { value: 'BC', text: 'British Columbia' },
    { value: 'MB', text: 'Manitoba' },
    { value: 'NB', text: 'New Brunswick' },
    { value: 'NL', text: 'Newfoundland and Labrador' },
    { value: 'NT', text: 'Northwest Territories' },
    { value: 'NS', text: 'Nova Scotia' },
    { value: 'NU', text: 'Nunavut' },
    { value: 'ON', text: 'Ontario' },
    { value: 'PE', text: 'Prince Edward Island' },
    { value: 'QC', text: 'Quebec' },
    { value: 'SK', text: 'Saskatchewan' },
    { value: 'YT', text: 'Yukon Territory' }
  ]
}
export const countryLabels = {
  US: 'USA',
  CA: 'Canada'
}
export const months = [
  { value: '01', text: '01 - January', disabled: false },
  { value: '02', text: '02 - February', disabled: false },
  { value: '03', text: '03 - March', disabled: false },
  { value: '04', text: '04 - April', disabled: false },
  { value: '05', text: '05 - May', disabled: false },
  { value: '06', text: '06 - June', disabled: false },
  { value: '07', text: '07 - July', disabled: false },
  { value: '08', text: '08 - August', disabled: false },
  { value: '09', text: '09 - September', disabled: false },
  { value: '10', text: '10 - October', disabled: false },
  { value: '11', text: '11 - November', disabled: false },
  { value: '12', text: '12 - December', disabled: false }
]
export function filterForExclusions(exclusions) {
  const filtered = JSON.parse(JSON.stringify(states))

  if (exclusions.includes('Canada')) {
    delete filtered.CA
  }
  filtered.US = filtered.US.filter(state => !exclusions.includes(state.value))
  return filtered
}
export function unabbreviateState(abbr) {
  let stateName = abbr
  Object.keys(states).forEach(country => {
    states[country].forEach(state => {
      if (state.value === abbr) {
        stateName = state.text
      }
    })
  })
  return stateName
}
export function isExcluded(customer, exclusions) {
  if (exclusions.includes(customer.shippingInfo.state)) {
    return true
  }
  return exclusions.includes('Canada') && customer.shippingInfo.country === 'CA'
}
